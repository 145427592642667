import * as React from 'react';

// Utils
import { verifyProperty } from '@src/shared/src/util/general';
import { t } from '@toolkit/util/i18n';
// Constants
// Actions
// Models
import { RentalVehicleModel } from '@src/shared/src/models';
// Interfaces
// Interfaces
// Components
// Styles

type Props = {
  rentalVehicle: RentalVehicleModel;
};

const RentalCarDetails: React.FC<Props> = (props: Props) => {
  return (
    <div className="rental-section">
      <div className="rental-section-row">
        {verifyProperty(-1, props, ['rentalVehicle', 'passengerCount']) > 0 && (
          <div className="rental-option">
            <i className="icon-people" />
            {props.rentalVehicle.passengerCount}&nbsp;
            {t('rental.label.passengers')}
          </div>
        )}
        {verifyProperty(-1, props, ['rentalVehicle', 'doorsCount']) > 0 && (
          <div className="rental-option">
            <i className="icon-rental_car" />
            {props.rentalVehicle.doorsCount}&nbsp;
            {t('rental.label.doorsCount')}
          </div>
        )}
        {verifyProperty(undefined, props, ['rentalVehicle', 'airCondition']) && (
          <div className="rental-option">
            <i className="icon-ac_unit" />
            {t('rental.label.airCondition')}&nbsp;
          </div>
        )}
        {verifyProperty(-1, props, ['rentalVehicle', 'baggageBig']) > 0 && (
          <div className="rental-option">
            <i className="icon-work" />
            {props.rentalVehicle.baggageBig}&nbsp;
            {t('rental.label.baggageBig')}
          </div>
        )}
        {verifyProperty(-1, props, ['rentalVehicle', 'baggageSmall']) > 0 && (
          <div className="rental-option">
            <i className="icon-hand-luggage" />
            {props.rentalVehicle.baggageSmall}&nbsp;
            {t('rental.label.baggageSmall')}
          </div>
        )}
      </div>
    </div>
  );
};

export default RentalCarDetails;
