import * as React from 'react';

// Utils
import { formatDate } from '@src/shared/src/util/date';
// Constants
import { DATE_FORMAT_TYPES, VEHICLE_TYPES } from '@src/shared/src/const/app';
// Actions
// Models
import { SearchModel, RentalModel } from '@src/shared/src/models';
// Interfaces
// Components
import { getOperatorImageDetailURL } from '@toolkit/util/app';
import { useTranslation } from 'react-i18next';
// Styles
import '../styles/RentalPickup.scss';

type Props = {
  search: SearchModel;
  rental: RentalModel;
};

const RentalPickUpInfo: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();

  return (
    <div className="rental-pickup">
      <div>
        <div className="rental-pickup-row">
          <strong>{t('RentalPickUpInfo.label.pickUp')}:&nbsp;</strong>
          {props.rental.rentalSearch.depStationName},{' '}
          {formatDate(props.search.depAt, DATE_FORMAT_TYPES.LONG_DATE_TIME)}
        </div>
        <div className="rental-pickup-row">
          <strong>{t('RentalPickUpInfo.label.return')}:&nbsp;</strong>
          {props.rental.rentalSearch.arrStationName},{' '}
          {formatDate(props.search.arrAt, DATE_FORMAT_TYPES.LONG_DATE_TIME)}
        </div>
      </div>
      <div className="rental-pickup-image">
        <img
          src={getOperatorImageDetailURL(
            props.rental.rentalSearch.aggregator.effectiveIdent,
            VEHICLE_TYPES.RENTAL,
          )}
        />
      </div>
    </div>
  );
};

export default RentalPickUpInfo;
