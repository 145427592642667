import * as React from 'react';

// Utils
import { useTranslation } from 'react-i18next';
// Constants
// Actions
// Models
import { RentalFareModel } from '@src/shared/src/models';
// Interfaces
// Interfaces
// Components
// Styles

type Props = {
  fare: RentalFareModel;
};

const RentalRate: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();

  const getMilageText = () => {
    if (props.fare.mileageUnlimited) {
      return t('RentalRate.label.unlimitedMileage');
    }
    if (props.fare.mileageFreeUnit !== 'km') {
      return t('RentalRate.label.limitedMileageKm', { distance: props.fare.mileageFreeDistance });
    } else {
      return t('RentalRate.label.limitedMileageMi', { distance: props.fare.mileageFreeDistance });
    }
  };

  return (
    <div className="rental-section">
      <div className="rental-label">{t('RentalCarExtras.label.rate')}</div>
      <div className="rental-option">
        <i className="icon-directions" />
        {getMilageText()}
      </div>
    </div>
  );
};

export default RentalRate;
