import * as React from 'react';

// Utils
import { t } from '@toolkit/util/i18n';
// Constants
// Actions
// Models
// Interfaces
// Components
// Styles

type Props = {
  vehicleExample: string;
  vehicleGroup: string;
};

const RentalTitle: React.FC<Props> = (props: Props) => {
  return (
    <div className="rental-title">
      <h2 className="rental-title-name">{props.vehicleExample}</h2>&nbsp;
      <span className="rental-title-group">
        {`| ${t('rental.titel.similar')} (${props.vehicleGroup})`}
      </span>
    </div>
  );
};

export default RentalTitle;
